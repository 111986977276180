import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "reactstrap";
import {
    register,
    getCredentials,
    registerSocialLogin,
    registerSocialLoginTEMP,
    login,
    checkEternaviewCreated,
  } from "../../redux/actions/index";
  import connection from "../../config/connection";
  import Loader from "react-loader";

const AuthCallbackFb = () => {

    const dispatch = useDispatch();

    let [email, setEmail] = useState("");
    let [emailError, setEmailError] = useState("");
    let [password, setPassword] = useState("");
    let [passwordError, setPasswordError] = useState("");
    let [inputtype, setInputType] = useState(false);
    let [loaded, setLoaded] = useState(true);
  


  useEffect(() => {
    const hash = window.location.hash.substr(1);
    const result = hash.split('&').reduce((res, item) => {
      let parts = item.split('=');
      res[parts[0]] = decodeURIComponent(parts[1]);
      return res;
    }, {});

    if (result.access_token) {
        console.log(result,'fbresult');
      console.log('Access Token:', result.access_token);
      fetchFacebookUserData(result.access_token);
      // You can now use this access token to make API calls or set user session
    }
  }, []);


  const fetchFacebookUserData = (accessToken) => {
    const url = `https://graph.facebook.com/me?fields=id,name,email,picture&access_token=${accessToken}`;
  
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log('User Data:', data);
      //  alert(data.name);
        


        const fbresponse = {
            Name: data.name,
            email: data.email,
            token: data.id,
            ProviderId:'facebook',// response.graphDomain,
            accessToken: accessToken,
          };
    
          fbLogin(fbresponse);

        //return data;
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };

  const fbLogin = (payload) => {
    //alert('dispatch');
    dispatch(getCredentials((res) => {
      console.log(res, 'abhishek')
      if (res.status == true) {
        let payload1 = {
          "username": payload.email,
          "client_id": res.res.client_id,
          "client_secret": res.res.client_secret,
        }
      //  alert('registerSocialLoginTEMP');
        dispatch(registerSocialLoginTEMP(payload1, (result) => {
          console.log(result, 'AbhishResult')
          setLoaded(true);
          if (result.error) {
            if (result.error == "Your account is inactive.Please check your email to activate your account.") {
              toast("Your account is inactive.Please check your email to activate your account. or contact us.", {
                position: "top-right",
                autoClose: 10000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
              return
            }
            toast(result.error_description, {
              position: "top-right",
              autoClose: 10000,
              type: 'error',
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            if (!result.error_description) {
              toast(result.error, {
                position: "top-right",
                autoClose: 10000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }

          } else if (result.access_token) {
            toast('You are successfully logged into application.', {
              position: "top-right",
              autoClose: 10000,
              type: 'success',
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            sessionStorage.setItem("token", result.access_token)
            //   props.props.history.push('/');
            window.location.href = `https://eternaview.com/?access_token=${result.access_token}`
          }
        }));
      }
    }));
  }


  return (
    <div>
    
      Authenticating...
    </div>
  );
};

export default AuthCallbackFb;