import React, { useState, useEffect, useRef } from "react";
import { Circles } from "react-loader-spinner";
import { isMobile, isTablet } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FRONTEND_ENDPOINT,
  environment,
  FaceBook_Token,
} from "../../config/connection";
import "react-toastify/dist/ReactToastify.css";
import Login from "../../components/Login";
import RegisterComponent from "../../components/Register";
import Thankyou from "../auth/VerifyEmail";
import Loader from "react-loader";
import tick from "../../Images/purpal_check.png";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";
import connection from "../../config/connection";
import {
  getSubCategoriesByCatOpen,
  getCategoriesOpen,
  getCategories,
  getPlans,
  getPromoCodes,
  verifyUserSubscription,
  createCheckoutSession,
  sharePixelTrack,
} from "../../redux/actions/index";
import ReactPixel from "react-facebook-pixel";
import FacebookPixel from "../../MetaComponents/Facebookpixel";
import Cookies from "js-cookie";
import PricingFaqModel from "./components/pricingFaq";
import GiftPopUp1 from "../../components/Gifts/giftsPopUp";
import GiftPopUp2 from "../../components/Gifts/giftsPopUpsecond";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {callback_fb_px_event} from "../../MetaComponents/FBMetaFunction";



function GeneralDatingPlans(props) {
  
  const [activePlan,setActivePlan] = useState({})
  // Login/Sign up Pop up
  const location = useLocation()
  
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [isModelOpen, setisModelOpen] = useState({
    isLoginOpen: false,
    isSignUpOpen: false,
    isThankyouOpen: false,
    isDivAppend: false,
    appededDivIndex: null,
  });
  const [isPricingFaq, SetisPricingFaq] = useState(false);
  const [giftSection2,setGiftsSection2] = useState(false)
  const dispatch = useDispatch();
  const paramvalue = useParams();
  const [clickedPlan, setClickedPlan] = useState();
  const [planVerify, setPlanVerify] = useState("");
  const [plans, setPlans] = useState([]);
  const [renewalPlans,setRenewalPlans] = useState([])
  const [promocodes, setPromocodes] = useState([]);
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planAmount, setPlanAmount] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [subCategories, setSubCategories] = useState({
    subCategories: [],
    catName: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [subCategory, setSubcategory] = useState(0);
  const [typecreates, setTypeCreate] = useState();
  const [paramUrl, setParamUrl] = useState("");
  const [catName, setCatName] = useState("Legacy");
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  const auth = useSelector((state) => state);
  const [planDetai, setPlanDetail] = useState(false);
  const elementRef = useRef(null);
  const toggleFaqModel = (param) =>{
    SetisPricingFaq(param)
  }
  useEffect(() => {
    // checkQueryUrl()
    verifyUserSubscriptions();
    getCategorie();
    getSubCategories();

    dispatch(
      getPromoCodes(auth.auth.loginUserToken, (res) => {
        if (res.data && res.data.length) {
          setPromocodes(res.data);
        }
      })
    );

    dispatch(
      getPlans(
        auth.auth.loginUserToken ? auth.auth.loginUserToken : "",
        (result) => {
          // const legacyData = result.data.filter(
          //   (curr) => curr.name_slug === "Legacy"
          // );

          // const legacyData = result.data.filter(
          //   (curr) => (curr.name_slug === "Legacy" || curr.name_slug == "A 5 year")
          // );
          if(result?.data){
            console.log('result?.data',result?.data);
            const plansWithParent = result?.data?.filter(curr=>(curr.parent_plan))
            const plans = result?.data?.filter(curr=>(curr.parent_plan == null && curr.category.name==='Dating'))
            setPlans(plans);
            setRenewalPlans(plansWithParent)
          }
        }
      )
    );

    setParamUrl(paramvalue.id);
  }, [auth.auth.loginUserToken]);

  const verifyUserSubscriptions = (cb) => {
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    dispatch(
      verifyUserSubscription(auth.auth.loginUserToken, (res) => {
        if (res.is_active == false) {
          localStorage.clear();
          // window.location.href = connection.homePage
          // this.props.history.replace("/");
        } else {
          if (res.data.length > 0) {
            res.data.map((mod, ind) => {
                  if(mod.updated_details.progress_status != "complete"){
                    setActivePlan(mod)
                    loadSelectChange({id:mod.subscribe_plan__category__id})
                  }else{
                    setLoaded(false)
                  }
            });
          }
          if (res.data) {
            setPlanVerify(res.data);
            if (cb) {
              cb(res.data);
            }
          }
        }
      })
    );
  };

  const nextCreate = (param) => {
    setLoaded(true)
    let active_plan=Object.keys(activePlan).length ? activePlan :param?param:{}
    console.log(Object.keys(active_plan).length>0, active_plan[0]  ,"<- active_plan");
    const planStatus = active_plan[0]?.active_status ? active_plan[0]?.active_status : active_plan?.active_status
    const status = active_plan[0]?.status ? active_plan[0]?.status : active_plan?.status
    const planCategory = active_plan[0]?.updated_details?.category   ? active_plan[0]?.updated_details?.category : 
     active_plan?.updated_details?.category ?  active_plan?.updated_details?.category  : 
     category
    if(Object.keys(active_plan).length>0  &&planStatus == "active" && status == "paid" ){
       const typecreate = active_plan[0]?.subscribe_plan__plan_type == 'video_audio' ? 0 : active_plan?.subscribe_plan__plan_type == 'video_audio' ? 0 : 1
       const views = active_plan[0]?.views ? active_plan[0]?.views : active_plan?.views
       localStorage.setItem('typecreate', typecreate);
      props.history.push(
          `/dating/create/eternaview/${planCategory}+${subCategory}+${typecreate}+${views}`
        );
     }
    else {
      setPlanDetail(true);
    }

 }

  // const nextCreate = (param) => {
  //   setLoaded(true);
  //   let catName = subCategories.catName;
  //   let redirection = false;
  //   let viewId = "";

  //   if (category != "0" && subCategory != "0") {
  //     localStorage.setItem("typecreate", typecreates);
  //     let typecreate = typecreates == 0 ? "video_audio" : "audio";
  //     if (planVerify.length > 0 || param?.length > 0) {
  //       let tempPlan;
  //       if (planVerify?.length > 0) {
  //         tempPlan = planVerify;
  //       } else if (param?.length > 0) {
  //         tempPlan = param;
  //       }
  //       tempPlan.map((mod, ind) => {
  //         if (category == mod.subscribe_plan__category__id) {
  //           if (mod.active_status == "active" && mod.status == "paid") {
  //             if (
  //               (mod.updated_details.progress_status == "initial" ||
  //                 mod.updated_details.progress_status == "in-progress") &&
  //               mod.subscribe_plan__plan_type == typecreate &&
  //               mod.updated_details.category == category
  //             ) {
  //               redirection = true;
  //               viewId = mod.views;
  //               return;
  //             }
  //           } else {
  //             alert(
  //               "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
  //             );
  //           }
  //         } else if (mod.subscribe_plan__category__id == 9999) {
  //           if (mod.active_status == "active" && mod.status == "paid") {
  //             if (
  //               (mod.updated_details.progress_status == "initial" ||
  //                 mod.updated_details.progress_status == "in-progress") &&
  //               mod.subscribe_plan__plan_type == typecreate &&
  //               mod.updated_details.category == category
  //             ) {
  //               redirection = true;
  //               viewId = mod.views;
  //               return;
  //             }
  //           } else {
  //             alert(
  //               "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
  //             );
  //           }
  //         }
  //       });
  //     }

  //     if (redirection == true) {
  //       props.history.push(
  //         `/create/eternaview/${category}+${subCategory}+${typecreates}+${viewId}`
  //       );
  //     } else {
  //       setPlanDetail(true);
  //     }
  //   }
  // };

  useEffect(() => {
    if (planDetai) {
      if(clickedPlan.plans.length > 1){
        getPlan(
          clickedPlan.plans[0].id,
          clickedPlan.plans[0].plan,
          clickedPlan.plans[0]?.amount + clickedPlan.plans[1]?.amount
        );
      }else{
        getPlan(
          clickedPlan.plans[0].id,
          clickedPlan.plans[0].plan,
          clickedPlan.plans[0]?.amount 
        );
      }

    }
  }, [planDetai]);

  const getCategorie = () => {
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    getCategories({ token: auth.auth.loginUserToken }, (res) => {
      if (res.data && res.data.length > 0) {
        setCategories(res.data);
      }
    });
  };
  const getSubCategories = () => {
    dispatch(
      getCategoriesOpen((res) => {
        if (res.data && res.data.length > 0) {
          setCategories(res.data);
          const lagecy_data = res.data.filter(
            (item, i) => item.name == "Dating"
          );
          // loadSelectChange(lagecy_data[0]);
        }
      })
    );
  };

  const loadSelectChange = (item) => {
    setTypeCreate(0);
    setCategory(item.id);
    dispatch(
      getSubCategoriesByCatOpen(item.id, (res) => {
        setSubCategories({
          subCategories: res.data.sub_categories,
          catName: res.data.name,
        });
        setSubcategory(res.data.sub_categories[0].id);
      })
    );
  };

  function getPlan(price, plan, amount) {

    callback_fb_px_event('InitiateCheckout',auth,dispatch);

    // window.fbq("track", "InitiateCheckout");
    setPriceLookupKey(price);
    setPlanId(plan);
    setPlanAmount(amount);

    localStorage.setItem("priceLookupKey", price);
    localStorage.setItem("planId", plan);

    let body = {
      lookup_key: plan,
      domain: `${FRONTEND_ENDPOINT}/${splitLocation[1]}/plans/${category}+${subCategory}+${typecreates}`,
      planAmount: amount,
      basePath: `${window.location.origin}/${splitLocation[1]}/plans/${category}+${subCategory}+${typecreates}`,
    };
    console.log('body',body);
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    dispatch(
      createCheckoutSession(body, auth.auth.loginUserToken, (result) => {
        if(result.session_url)
        sessionStorage.removeItem("giftType")
        window.location.href = result.session_url;
        if(result.error)
        alert("Please try again latter.")
      })
    );
  }
  const checkLoginStatus = (resp) => {
    const isGift = sessionStorage.getItem("giftType")
    loadSelectChange(resp.category)
    if (!auth.auth.loginUserToken&&!sessionStorage.getItem("token")) {
      if(isGift == 2){
        // loadSelectChange(resp.category)
        setGiftsSection2(true)
        const GiftSubscribe = {
          cat_id: resp.category.id,
          plan_id:resp.plans[0]?.plan,
          price_id:resp.plans[0].id
         }
         sessionStorage.setItem("subscribeObj",JSON.stringify(GiftSubscribe))
        
      }
      else{
        openLoginPop();                    //Remove if condition if client doens't want checkout without login
      }
      // openLoginPop();

    } else {
      if(isGift == 1){
        setLoaded(true)
        // loadSelectChange(resp.category)
        setTimeout(() => {
          nextCreate();
        }, 3000);
      }
      else{
        // loadSelectChange(resp.category)
        setGiftsSection2(true)
        console.log(resp,"<- resp");
        const GiftSubscribe = {
          cat_id: resp.category.id,
          plan_id:resp.plans[0]?.plan,
          price_id:resp.plans[0].id
         }
         sessionStorage.setItem("subscribeObj",JSON.stringify(GiftSubscribe))
      }
      
    }
  };

  const updateGiftPopUp = (state) =>{
    setGiftsSection2(state)
  }

  const openLoginPop = () => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: false,
      isLoginOpen: true,
    }));
  };

  const openSignupPopUp = (ret) => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: true,
      isLoginOpen: false,
    }));
  };

  const closeLoginPopup = (ret) => {
    if (auth.auth.loginUserToken || sessionStorage.getItem("token")) {
     
      const isGift = sessionStorage.getItem("giftType")
      if(isGift == 1){
        dispatch(verifyUserSubscriptions(nextCreate));
      }
      else{
        setGiftsSection2(true)
      }
      setisModelOpen((prev) => ({
        ...prev,
        isLoginOpen: false,
      }));
    } else {
      setisModelOpen((prev) => ({
        ...prev,
        isLoginOpen: false,
      }));
    }
  };

  const cloaseAnyModel = () => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: false,
      isLoginOpen: false,
    }));
  };

  // const closeSignupPopup = (ret) => {
  //   setisModelOpen((prev) => ({
  //     ...prev,
  //     isSignUpOpen: false,
  //     isThankyouOpen: true,
  //   }));
  // };


  const closeSignupPopup = (ret) => {
    if (auth.auth.loginUserToken || sessionStorage.getItem("token")) {
      checkLoginStatus(clickedPlan)
      setisModelOpen((prev) => ({
        ...prev,
        isLoginOpen: false,
        isSignUpOpen: false,
        isThankyouOpen: true,
      }));
    }
  };


  return (
    <>
    <section className="plan_outer new_plan_new">
      {
        loaded&&
        <Circles wrapperClass="circle-spinner"/>
      }
      
      <FacebookPixel trackName="ViewContent" />
      {loaded && <Loader loaded={loaded} />}
      <div className="container custom_h_container plan_container">
        <div className="pricing_section_new">
          <div className="row justify-content-center">
            {plans.length > 0 &&
              plans.map((plan, index) => (
                <div className="col-md-4 custom_four_col_new">
                  <div className="pricing_inner_new_design">
                    <div className="standard_plan_new">
                      {plan.name_slug === "Legacy" ? "Legacy" : plan.name_slug}{" "}
                      Plan
                    </div>
                    <div className="standard_plan_bottom">
                    <div class="ribbon"  onClick={() => {
                        SetisPricingFaq(true);
                      }}>
                      <span class="ribbon3">FAQ</span>
                    </div>
                    <p className="f_price"><sup>$</sup>
                      { 
                       (plan?.plans[0]?.amount?plan?.plans[0]?.amount:0)+
                        (plan?.plans[1]?.amount?plan?.plans[1]?.amount:0)
                      }{" "}
                    <sup className="zero_price">00</sup>
                          </p>
         
                    {/* <p className="price_cover_div">
                      (i.e. ${ 
                             (plan?.plans[0]?.amount?plan?.plans[0]?.amount:0)+
                             (plan?.plans[1]?.amount?plan?.plans[1]?.amount:0)
                             }{" "}
                      paid now covers{" "}
                      {plan.plan_time}  {plan.plan_time > 1 ? "years" :"year"},
                      after which only a Subscription fee will be due to
                      extend.){" "}
                    </p> */}
                    {    renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id).length >0 ?
                    (
                      renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id)
                      .map((curr) => (
                        curr?.plans &&
                        curr?.plans.length > 0 &&
                        curr.plans.filter((curr) => curr.is_annual == false).length>0&&
                        curr.plans.filter((curr) => curr.is_annual == false)
                          .map((curr) => (
                          <>
                            <p className="price_cover_div">
                            This price covers the one-time Creation Fee ($100), plus a {`${(plan.plan_time)} year`} subscription {`($${curr.amount})`}
                            </p>
                            <p className="ann_subs">
                              {/*    fee of $${curr.amount} */}
                              { 
                              `Just a small annual subscription fee
                              will be needed to renew after ${(plan.plan_time)}  ${(plan.plan_time>1 ? "years":"year")}`
                             }
                             </p>
                          </>
                          ))
                      ))
                    ): 
                    plan?.plans && 
                    plan?.plans.length > 0 &&
                    plan?.plans.filter((curr) => curr.is_annual).length>0 ? (
                      plan?.plans &&
                      plan?.plans.length > 0 &&
                      plan?.plans
                        .filter((curr) => curr.is_annual)
                        .map((curr) => (
                          <>
                          <p className="price_cover_div">
                          This price covers the one-time Creation Fee ($100), plus a {`${(plan.plan_time)} year`} subscription {`($${curr.amount})`}
                          </p>
                          <p className="ann_subs">
                            {/* fee of $${curr.amount} */}
                            {
                              `Just a small annual subscription fee
                              will be needed to renew after ${(plan.plan_time)} ${(plan.plan_time>1 ? "years":"year")}`
                            }
                            </p>
                        </>
                         
                        ))
                    )
                    :
                    (
                      <p className="ann_subs">$0 Annual Subscription </p>
                    )}
                    {plan.plans.length == 2 && (
                      <button
                        className="Puchase_now_btn_new"
                        onClick={() => {
                          setClickedPlan(plan);
                          checkLoginStatus(plan);
                        }}
                      >
                        Get started
                      </button>
                    )}
                    {plan.plans.length == 1 && (
                      <button
                        className="Puchase_now_btn_new"
                        onClick={() => {
                          setClickedPlan(plan);
                          checkLoginStatus(plan);
                          // setClickedPlan(plan);
                          // getPlan(
                          //   plan.plans[0].id,
                          //   plan.plans[0].plan,
                          //   plan.plans[0].amount
                          // );
                        }}
                      >
                        Get started
                      </button>
                    )}
                    <p className="gift_text">Gift options are also available</p>
                  </div>
                  </div>
                  
                </div>
              ))}
          </div>
          <div> 

              {/* <ul className="pricing_new_ul">
                      <li>
                        <img src={tick} />
                        Simple, automated interview process
                      </li>
                      <li>
                        <img src={tick} />
                        Edit answers any time
                      </li>
                      <li>
                        <img src={tick} />
                        Unlimited invites and sharing
                      </li>
                      <li>
                        <img src={tick} />
                        No charge to viewers
                      </li>
                      <li>
                        <img src={tick} />
                        Responds to questions spoken or typed by viewers
                      </li>
                      <li>
                        <img src={tick} />
                        Storage in the Amazon Web Services cloud
                      </li>
                      <li>
                        <img src={tick} />
                        Creator can download their Eternaview
                      </li>
                      <li>
                        <img src={tick} />
                        Privacy controls
                      </li>
                    </ul>
                    {isModelOpen.isDivAppend ? (
                      <a
                        // href="#"
                        className="click_view"
                        onClick={() => {
                          setisModelOpen((prev) => ({
                            ...prev,
                            isDivAppend: !isModelOpen.isDivAppend,
                          }));
                        }}
                      >
                        Click here to hide information
                      </a>
                    ) : (
                      <a
                        id={"appended"}
                        // href="#"
                        className="click_view"
                        onClick={() => {
                          // scrollIntoView(index)
                          setisModelOpen((prev) => ({
                            ...prev,
                            isDivAppend: true,
                          }));
                        }}
                      >
                        Click here to view full plan details on..
                      </a>
                    )}
                    <p className="com_title">Compatibility</p>
                    <p className="com_title">Features</p>
                    <p className="com_title">Usage Allowances</p>
                    {isModelOpen.isDivAppend &&
                    (
                        <div className="pricing_toggle_div">
                          <div  className="pricing_main_toggle_title">
                            Compatibility
                          </div>
                          <ul className="pricing_new_ul">
                            <li>
                              <img src={tick} />
                              PC’s - Windows and Mac
                            </li>
                            <li>
                              <img src={tick} />
                              iPAD’s and Android Tablets
                            </li>
                            <li>
                              <img src={tick} />
                              iPhones and Android phones
                            </li>
                            <li>
                              <img src={tick} />
                              Chrome or Safari browser is recommended
                            </li>
                          </ul>
                          <div className="pricing_main_toggle_title">
                            Features
                          </div>
                          <ul className="pricing_new_ul">
                            <li>
                              <img src={tick} />
                              Recording through webcam or mobile camera
                              (external microphone optional)
                            </li>
                            <li>
                              <img src={tick} />
                              Recording up to Full HD (Subject to the resolution
                              of the recording device. Certain devices, like
                              iPAD, may be limited to only HD)
                            </li>
                            <li>
                              <img src={tick} />
                              Video is optimized for playback on various devices
                              and internet speeds
                            </li>
                          </ul>
                          <div className="pricing_main_toggle_title">
                            Usage Allowances
                          </div>
                          <ul className="pricing_new_ul">
                            <li>
                              <img src={tick} />
                              Each subscription is for one Eternaview (i.e. full
                              interview consisting of ~190 questions)
                            </li>
                            <li>
                              <img src={tick} />
                              Up to 5 hours of recorded video
                            </li>
                            <li>
                              <img src={tick} />
                              No limit on re-recording answers before submitting
                            </li>
                            <li>
                              <img src={tick} />
                              Up to 5 edits per year after
                              submitting your initial recordings
                            </li>
                            <li>
                              <img src={tick} />
                              Viewers can ask up to 5 questions
                              to your Eternaview per year
                            </li>
                            <h6 className="note_text">
                              Note:- Note that these Usage Allowances should be
                              plenty for most subscribers. Also, edit and query
                              allowances are replenished every year. Rare
                              exceptions may apply to Usage Allowances, as
                              detailed in our Terms of Use.
                            </h6>
                          </ul>
                        </div>
                      )} */}
                  </div>
        </div>
      </div>
      <div
        id="myLoginPopup"
        style={{
          display: isModelOpen.isLoginOpen === true ? "block" : "none",
        }}
        className={
          isModelOpen.isLoginOpen === true
            ? "modal fade show"
            : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <button
                onClick={() => {
                  cloaseAnyModel();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            <div className="modal-body custom_body custom_modal_body">
              <Login
                customClass={{ modalTop: "modalh4" }}
                isLoginPopup={true}
                isSignupPop={openSignupPopUp}
                closeLoginPopup={closeLoginPopup}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="mySignupPopup"
        style={{
          display: isModelOpen.isSignUpOpen ? "block" : "none",
        }}
        className={
          isModelOpen.isSignUpOpen ? "modal fade show" : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={() => {
                  cloaseAnyModel();
                }}
              >
                &times;
              </button>
            <div className="modal-body custom_body custom_modal_body">
              <RegisterComponent
                customClass={{ modalTop: "modalh4" }}
                openLoginPopUp={openLoginPop}
                closeSignupPopup={closeSignupPopup}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Thankyou pop up commented on client request to remove verfication setup after register */}
      {/* <div
        id="thankyouPopup"
        style={{
          display: isModelOpen.isThankyouOpen ? "block" : "none",
        }}
        className={
          isModelOpen.isThankyouOpen ? "modal fade show" : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={() => {
                  cloaseAnyModel();
                }}
              >
                &times;
              </button>
            <div className="modal-body custom_body custom_modal_body">
              {isModelOpen.isThankyouOpen && (
                <Thankyou customClass={true} openLoginPopUp={openLoginPop} />
              )}
            </div>
          </div>
        </div>
      </div> */}
      <GiftPopUp1 isOpen={true} state={updateGiftPopUp} />
      <GiftPopUp2 isOpen ={giftSection2} state={updateGiftPopUp} PlanDetails={clickedPlan}/>
      <PricingFaqModel  toggleFaqModel={toggleFaqModel} isPricingFaq={isPricingFaq}/>
    </section>

    <section className="all_plan_new_section">
      <div className="container custom_h_container">
        <div className="row">
          <div className="col-md-12">
             <div className="all_plan_title">All Plans include the following:</div>
          </div>
        </div>
        <div className="row">
           <div className="col-md-4">
             <ul className="new_plan_listing_ul">
               <li><i class="fas fa-check-square"></i><span>Simple, automated interview process</span></li>
               <li><i class="fas fa-check-square"></i><span>Edit answers any time</span></li>
               <li><i class="fas fa-check-square"></i><span>Unlimited invites and sharing</span></li>
             </ul>
           </div>
           <div className="col-md-4">
             <ul className="new_plan_listing_ul">
               <li><i class="fas fa-check-square"></i><span>No charge to viewers</span></li>
               <li><i class="fas fa-check-square"></i><span>Responds to questions spoken or typed</span></li>
               <li><i class="fas fa-check-square"></i><span>Storage in the cloud</span></li>
             </ul>
           </div>
           <div className="col-md-4">
             <ul className="new_plan_listing_ul">
               <li><i class="fas fa-check-square"></i><span>Creator can download their Eternaview</span></li>
               <li><i class="fas fa-check-square"></i><span>Privacy controls</span></li>
               <li><i class="fas fa-check-square"></i><span>Eternaview Satisfaction Commitment</span></li>
             </ul>
           </div>
        </div>
      </div>
    </section>

    <section className="all_plan_new_section">
      <div className="container custom_h_container">
        <div className="row justify-content-center">
           <div className="col-md-4">
             <div className="all_plan_title compatibility_title">Compatibility</div>
             <ul className="new_plan_listing_ul">
               <li><i class="fas fa-check-circle"></i><span>PCs - Windows and Mac</span></li>
               <li><i class="fas fa-check-circle"></i><span>iPADs and Android Tablets</span></li>
               <li><i class="fas fa-check-circle"></i><span>iPhones and Android phones</span></li>
               <li><i class="fas fa-check-circle"></i><span>Chrome or Safari browser is recommended</span></li>
             </ul>
           </div>
           <div className="col-md-4">
             <div className="all_plan_title compatibility_title">Features</div>
             <ul className="new_plan_listing_ul">
              <li><i class="fas fa-check-circle"></i><span>Recording through webcam or mobile camera (external microphone optional)</span></li>
              <li><i class="fas fa-check-circle"></i><span>Recording up to Full HD (Subject to the resolution of the recording device. Certain devices, like iPAD, may be limited to only HD)</span></li>
              <li><i class="fas fa-check-circle"></i><span>Video is optimized for playback on various devices and internet speeds</span></li>
             </ul>
           </div>
           <div className="col-md-4">
             <div className="all_plan_title compatibility_title">Usage Allowances</div>
             <ul className="new_plan_listing_ul">
             <li><i class="fas fa-check-circle"></i><span>Each subscription is for one Eternaview (i.e. full interview consisting of ~190 questions)</span></li>
             <li><i class="fas fa-check-circle"></i><span>No limit on re-recording answers before submitting</span></li>
             <li><i class="fas fa-check-circle"></i><span>Up to 60 edits per year after submitting your initial recordings</span></li>
             <li><i class="fas fa-check-circle"></i><span>Viewers can ask up to 2500 questions to your Eternaview per year</span></li>
             <li><i class="fas fa-check-circle"></i><span>Up to 6 hours of recorded video</span></li>
     
             </ul>
           </div>
           </div>
      </div>
    </section>

    </>
  );
}

export default GeneralDatingPlans;
