import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEternaVideos, downloadFile } from '../../../redux/actions';
import { toast } from 'react-toastify';
import JsFileDownloader from 'js-file-downloader';
import Loader from "react-loader";
import { Modal, Button } from 'react-bootstrap';


const iosCheck =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

class DownloadEterna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eternaId: props.id,
            token: props.token,
            selectedLoader: 0,
            loaded: false,
            downloadUrl: null,
            showDownloadModal: false

        };
    }

    handleShow = () => {
        this.setState({ showModal: true });
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };
    downloadEterna(index) {
        this.props.getEternaVideos(this.state.eternaId, this.state.token, (res) => {
            if (res.answers && res.answers.length > 0) {
                var zip = new JSZip();


                try {
                    this.setState({ selectedLoader: index });
                    toast("Eternaview download started, You can continue while we download your Eternaview", {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'warning',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });

                    const remoteZips = res.answers.map(async (pack) => {

                        // pack is the URL for the auidio or video hosted on the server
                        const response = await fetch(pack.media_file, {
                            method: 'GET',
                            headers: new Headers({ 'Accept': '*/*' }),
                        });
                        const data = await response.blob();
                        var type = res.type == 'audio' ? '.mp3' : '.mp4';
                        var filename = pack.question.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_') + type;
                        console.log("file name is", filename);
                        zip.file(filename, data);
                        return data;
                    })



                    Promise.all(remoteZips).then(() => {
                        zip.generateAsync({ type: "blob" }).then((content) => {
                            if (iosCheck == true) {
                                const url = window.URL.createObjectURL(content);

                                this.setState({ downloadUrl: url });
                                this.handleShow();
                            }
                            saveAs(content, `EternaView-` + this.state.eternaId + ".zip");
                        })

                        toast("Eternaview downloaded successfully.", {
                            position: "top-right",
                            autoClose: 3000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                        this.setState({ selectedLoader: 0 });
                    })
                }
                catch (ex) {
                    toast('Unable to download, Please try after some time.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }



            }
            else {
                toast('Unable to donwload, Complete the Eternaview before download.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

        });
    }
    render() {
        return (
            <>
                <div className="download-button-actions" style={{ cursor: 'width:100%' }}>
                    <p className="downloadP">
                        Download :
                        <i className="fa fa-download" style={{ cursor: 'pointer' }} onClick={() => this.downloadEterna(this.state.eternaId)} title="Click here to download your etarnaview all answers." ></i>
                    </p>
                    {this.state.selectedLoader == this.state.eternaId ? (<div class="custom-loader"></div>) : ''}
                </div>











                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>File ready to download</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.downloadUrl && (
                            <a href={this.state.downloadUrl} download="videos.zip">
                                Click here to download your videos
                            </a>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    getEternaVideos: bindActionCreators(getEternaVideos, dispatch),
    downloadFile: bindActionCreators(downloadFile, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DownloadEterna);