import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import tick from "../../Images/purpal_check.png";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";
import { isMobile } from "react-device-detect";
import FacebookPixel from "../../MetaComponents/Facebookpixel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../Images/edit.png";
import { Redirect } from "react-router-dom";
import CheckoutWithStripe from "./CheckoutWithStripe";
import {
  getPlans,
  getPromoCodes,
  subscribeToPlans,
  createCheckoutSession,
  verifyUserSubscription,
  sharePixelTrack,
} from "../../redux/actions/index";
import copy from "copy-to-clipboard";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ReactPixel from "react-facebook-pixel";
import {
  FRONTEND_ENDPOINT,
  environment,
  FaceBook_Token,
} from "../../config/connection";
import connection from "../../config/connection";
import Cookies from "js-cookie";
import PricingFaqModel from "./components/pricingFaq";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function NewPlansPage(props) {
  const [renewalPlans,setRenewalPlans] = useState([])
  const [isPricingFaq, SetisPricingFaq] = useState(false);
  const [isModelOpen, setisModelOpen] = useState({isDivAppend:false,appededDivIndex:null});
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const paramvalue = useParams();
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planAmount, setPlanAmount] = useState(null);
  const [plans, setPlans] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [paramUrl, setParamUrl] = useState("");
  const [catName, setCatName] = useState(props?.location?.state?.catName);
  const auth = useSelector((state) => state);
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState("");
  let [sessionId, setSessionId] = useState("");
  const [subscription, setSubscription] = useState();
  const [planVerify, setPlanVerify] = useState("");
  const [pricingInfoModel, setPricingInfoModel] = useState(false);
  const [showPlanInfo, setShowPlanInfo] = useState(false);

  useEffect(() => {
    if (auth.auth.loginUserToken == "") {
      window.location.href = auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}` :  connection.homePage
      // props.history.push("/");
    }

    dispatch(
      verifyUserSubscription(auth.auth.loginUserToken, (res) => {
        if (res.data) {
          setPlanVerify(res.data.active_status);
        }
      })
    );

    dispatch(
      getPlans(auth.auth.loginUserToken, (result) => {
        // setPlans(result.data);
        // const legacyData = result.data.filter(
        //   (curr) => curr.name_slug === "Legacy"
        // );
        const plansWithParent = result?.data?.filter(curr=>(curr.parent_plan))
        const plans = result?.data?.filter(curr=>(curr.parent_plan == null))
        setPlans(plans);
        setRenewalPlans(plansWithParent)
      })
    );

    dispatch(
      getPromoCodes(auth.auth.loginUserToken, (res) => {
        if (res.data && res.data.length) {
          setPromocodes(res.data);
        }
      })
    );

    setParamUrl(paramvalue.id);

    ////// checkout ////////
    const query = new URLSearchParams(window.location.search);

    console.log(query);
    if (query.get("success")) {
      setSuccess("true");
      setSessionId(query.get("session_id"));
    } else {
      console.log(props);
    }

    if (query.get("canceled")) {
      setSuccess("false");
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );

      toast("Payment is unsuccessful", {
        position: "top-right",
        autoClose: 3000,
        type: "error",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }, [sessionId]);

  let stripePromise = null;

  const [publishableKey, setPublishableKey] = useState(null);

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );
  const toggleFaqModel = (param) =>{
    SetisPricingFaq(param)
  }
  const SuccessDisplay = ({ sessionId }) => {
    var priceLookupKey = localStorage.getItem("priceLookupKey");
    var planId = localStorage.getItem("planId");
    var email = localStorage.getItem("email");
    let capitalizedCategory = 'Legacy';
    if(splitLocation[1]=='dating')
    {
       capitalizedCategory = 'Dating';
    }
    let split = paramUrl.split("+");
    let body = {
      price_id: priceLookupKey,
      plan_id: planId,
      cat_id: split[0],
      plan_type:capitalizedCategory,
    };

    dispatch(
      subscribeToPlans(body, auth.auth.loginUserToken, (result) => {
        console.log(result);
        if (result.msg) {
          if(splitLocation[1]=='dating')
            {
            
              window.location.href = `/dating/thank-you-plan/${paramUrl}+${result.views}`;
            }
            else 
            {
              window.location.href = `/home/thank-you-plan/${paramUrl}+${result.views}`;
            }
         // window.location.href = `/home/thank-you-plan/${paramUrl}+${result.views}`;
        }
      })
    );
  };

  if (!success && message === "") {
  } else if (success == "true" && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }

  if (publishableKey) {
    stripePromise = loadStripe(
      "pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe"
    );
  } else {
    setPublishableKey(
      "pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe"
    );
  }

  function getPlan(price, plan, amount) {
    if (environment === "production") {
      ReactPixel.init("1499380397556107");
      ReactPixel.track("InitiateCheckout", {
        value: 60,
        currency: "USD",
      });

      const facebookObj = {
        access_token: FaceBook_Token,
        pixel_id: "1499380397556107",
        event_name: "InitiateCheckout",
        event_time: Math.floor(Date.now() / 1000),
        event_source_url: "https://www.eternaview.com/",
        action_source: "website",
        email: auth.auth.loginUserInfo.email,
        fbp: Cookies.get("_fbp"),
      };
      dispatch(sharePixelTrack(facebookObj, (result) => {}));
    }

    setPriceLookupKey(price);
    setPlanId(plan);
    setPlanAmount(amount);

    localStorage.setItem("priceLookupKey", price);
    localStorage.setItem("planId", plan);

    let body = {
      lookup_key: plan,
      domain: `${FRONTEND_ENDPOINT}/home/plans/${paramUrl}`,
      planAmount: amount,
      basePath: `${window.location.origin}/home/plans/${paramUrl}`,
    };

    dispatch(
      createCheckoutSession(body, auth.auth.loginUserToken, (result) => {
        if(result.session_url)
        window.location.href = result.session_url;
        if(result.error)
        alert("Please try again latter.")
      })
    );

    // createCheckoutSessionNow(plan, amount);
  }

  function copyPromocode(code) {
    copy(code);

    toast("Promocode is copied successfully", {
      position: "top-right",
      autoClose: 2000,
      type: "success",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <section className="plan_outer new_plan">
        <FacebookPixel trackName="ViewContent" />
        <div
          className="container pricing_container"
          style={{ marginBottom: 10 }}
        >
          {promocodes.length > 0 && (
            <div className="promo_div_1">
              <p>Please click to see offers</p>
              <button
                className="cancel_sub"
                type="submit"
                data-toggle="modal"
                data-target="#promoModal"
              >
                View Offers
              </button>
            </div>
          )}

          <div
            className="modal fade"
            id="promoModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="promoModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="promoModalLabel">
                    Promo Code
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {promocodes &&
                    promocodes.map((promo) => (
                      <div className="promo_div">
                        {promo.coupon.percent_off && (
                          <span>
                            Promo Code: <b>{promo.code}</b> -{" "}
                            {promo.coupon.percent_off}%
                          </span>
                        )}

                        {promo.coupon.amount_off && (
                          <span>
                            Promo Code: <b>{promo.code}</b> - $
                            {promo.coupon.amount_off}
                          </span>
                        )}
                        <button
                          onClick={() => copyPromocode(promo.code)}
                          data-dismiss="modal"
                        >
                          <i className="fal fa-copy"></i>
                        </button>
                      </div>
                    ))}
                  {/* <button type="button" className="payable_amount"><b>Please Pay:</b> ${planAmount}</button> */}
                </div>
              </div>
            </div>
          </div>
          {
            localStorage.getItem("typecreate") == 0 &&
            
                <div className="pricing_section_new">
                  <div className="row justify-content-center">
                 { plans.length > 0 &&
              plans.map((plan,index) => (
                    <div className="col-md-4 custom_four_col">
                      <div className="pricing_inner_new">
                        <div className="standard_plan">
                        <button
                      className="Puchase_faq_btn pricing_faq"
                      onClick={() => {
                        SetisPricingFaq(true);
                      }}
                    >
                      Pricing <br />
                      FAQ
                    </button>
                          {plan.name_slug} Plan
                        </div>
                        { renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id).length >0 ?
                    (
                      renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id)
                      .map((curr) => (
                        curr?.plans &&
                        curr?.plans.length > 0 && curr.plans.filter((curr) => curr.is_annual == false).length>0&&
                        curr.plans.filter((curr) => curr.is_annual == false)
                          .map((curr) => (
                            <p>${
                              
                     
                                plan?.plans
                                  .filter((curr) => curr.is_annual == false)
                                  .map((curr) => (curr.amount
                                  ))
                               -
                              curr.amount } One-time Creation fee +</p>
                          ))
                        // <p>${curr.amount} Annual Subscription </p>
                      ))
                    ):
                    plan?.plans &&
                        plan?.plans &&
                    plan?.plans.length > 0 &&
                    plan?.plans.filter((curr) => curr.is_annual == false).length>0 ? (
                      plan?.plans &&
                      plan?.plans.length > 0 &&
                      plan?.plans
                        .filter((curr) => curr.is_annual == false)
                        .map((curr) => (
                          <p>
                          ${curr.amount}{" "}
                          One-time Creation fee +
                        </p>
                        ))
                    ) : (
                      <p>$0 One-time Creation fee + </p>
                    )}


                    {
                       renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id).length >0 ?
                       (
                         renewalPlans.length > 0 && renewalPlans.filter(curr=>curr.parent_plan == plan.id)
                         .map((curr) => (
                           curr?.plans &&
                           curr?.plans.length > 0 && curr.plans.filter((curr) => curr.is_annual == false).length>0&&
                           curr.plans.filter((curr) => curr.is_annual == false)
                             .map((curr) => (
                               <p>${curr.amount+ (plan.plan_time > 1 ? ` Subscription for a ${plan.name_slug} term`: ' Annual Subscription ')}</p>
                             ))
                           // <p>${curr.amount} Annual Subscription </p>
                         ))
                       )
                    
                 :     plan?.plans &&
                 plan?.plans.length > 0 &&
                 plan?.plans.filter((curr) => curr.is_annual).length>0 ? (
                   plan?.plans &&
                   plan?.plans.length > 0 &&
                   plan?.plans
                     .filter((curr) => curr.is_annual)
                     .map((curr) => (
                       <p>${curr.amount} Annual Subscription </p>
                     ))
                 )
                 
                    : (
                      <p>$0 Annual Subscription </p>
                    )}
                    {/* <p>
                      ${plan?.plans[1]?.amount ? plan?.plans[1]?.amount : 0}{" "}
                      One-time Creation fee +
                    </p> */}

                    <p style={{ fontSize: 15 }}>
                      (i.e. ${(plan?.plans[0]?.amount?plan?.plans[0]?.amount:0) + (plan?.plans[1]?.amount?plan?.plans[1]?.amount:0)}{" "}
                      paid now covers{" "}
                       {plan.plan_time}  {plan.plan_time > 1 ? "years" :"year"},
                      after which only a Subscription fee will be due to
                      extend.){" "}
                    </p>

                        {plan.plans.length == 2 && (
                          <button
                            className="Puchase_now_btn"
                            onClick={() =>
                              getPlan(
                                plan.plans[0]?.id,
                                plan.plans[0]?.plan,
                                plan.plans[0]?.amount + plan.plans[1]?.amount
                              )
                            }
                          >
                           Get started
                          </button>
                        )}
                        {plan.plans.length == 1 && (
                          <button
                            className="Puchase_now_btn"
                            onClick={() =>
                              getPlan(
                                plan.plans[0]?.id,
                                plan.plans[0]?.plan,
                                plan.plans[0]?.amount
                              )
                            }
                          >
                           Get started
                          </button>
                        )}
                        <ul className="pricing_new_ul">
                          <li>
                            <img src={tick} />
                            Simple, automated interview process
                          </li>
                          <li>
                            <img src={tick} />
                            Edit answers any time
                          </li>
                          <li>
                            <img src={tick} />
                            Unlimited invites and sharing
                          </li>
                          <li>
                            <img src={tick} />
                            No charge to viewers
                          </li>
                          <li>
                            <img src={tick} />
                            Responds to questions spoken or typed by viewers
                          </li>
                          <li>
                            <img src={tick} />
                            Storage in the Amazon Web Services cloud
                          </li>
                          <li>
                            <img src={tick} />
                            Creator can download their Eternaview
                          </li>
                          <li>
                            <img src={tick} />
                            Privacy controls
                          </li>
                        </ul>
                        {
                         isModelOpen.isDivAppend && index == isModelOpen.appededDivIndex?      <a
                        //  href="#"
                         className="click_view"
                         onClick={() => {
                           setisModelOpen((prev) => ({
                             ...prev,
                             isDivAppend: !isModelOpen.isDivAppend,
                             appededDivIndex:index
                           }));
                         }}
                       >
                         Click here to hide information
                       </a>:  <a
                            // href="#"
                            className="click_view"
                            onClick={() => {
                              setisModelOpen((prev) => ({
                                ...prev,
                                isDivAppend: true,
                                appededDivIndex:index
                              }));
                            }}
                          >
                              Click here to view full plan details on..
                          </a>
                            
                        }
                        
                     
                        <p className="com_title">Compatibility</p>
                        <p className="com_title">Features</p>
                        <p className="com_title">Usage Allowances</p>
                        {isModelOpen.isDivAppend &&index == isModelOpen.appededDivIndex&&(
                          <div className="pricing_toggle_div">
                            <div className="pricing_main_toggle_title">
                              Compatibility
                            </div>
                            <ul className="pricing_new_ul">
                              <li>
                                <img src={tick} />
                                PC’s - Windows and Mac
                              </li>
                              <li>
                                <img src={tick} />
                                iPAD’s and Android Tablets
                              </li>
                              <li>
                                <img src={tick} />
                                iPhones and Android phones
                              </li>
                              <li>
                                <img src={tick} />
                                Chrome or Safari browser is recommended
                              </li>
                            </ul>
                            <div className="pricing_main_toggle_title">
                              Features
                            </div>
                            <ul className="pricing_new_ul">
                              <li>
                                <img src={tick} />
                                Recording through webcam or mobile camera
                                (external microphone optional)
                              </li>
                              <li>
                                <img src={tick} />
                                Recording up to Full HD (Subject to the
                                resolution of the recording device. Certain
                                devices, like iPAD, may be limited to only HD)
                              </li>
                              <li>
                                <img src={tick} />
                                Video is optimized for playback on various
                                devices and internet speeds
                              </li>
                            </ul>
                            <div className="pricing_main_toggle_title">
                              Usage Allowances
                            </div>
                            <ul className="pricing_new_ul">
                              <li>
                                <img src={tick} />
                                Each subscription is for one Eternaview (i.e.
                                full interview consisting of ~190 questions)
                              </li>
                              <li>
                                <img src={tick} />
                                Up to {plan.total_time} hours of recorded video
                              </li>
                              <li>
                                <img src={tick} />
                                No limit on re-recording answers before
                                submitting
                              </li>
                              <li>
                                <img src={tick} />
                                Up to {plan.no_of_edit} edits per year after
                                submitting your initial recordings
                              </li>
                              <li>
                                <img src={tick} />
                                Viewers can ask up to {plan.no_of_query}{" "}
                                questions to your Eternaview per year
                              </li>
                              <h6 className="note_text">
                                Note:- Note that these Usage Allowances should
                                be plenty for most subscribers. Also, edit and
                                query allowances are replenished every year.
                                Rare exceptions may apply to Usage Allowances,
                                as detailed in our Terms of Use.
                              </h6>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
          }

          {success == "false" && (
            <div className="promo_div_1 order_cancel">
              Payment not successfully made.
            </div>
          )}
          {showPlanInfo && (
            <div className="row contact_outer">
              <div className="col-lg-8 col-md-12 offset-lg-2">
                <div className="row bg_color67">
                  <div className="col-lg-8">
                    <div className="leg556">
                      <h3>Custom Plan</h3>
                      <p>
                        If you are a celebrity, influencer, corporate exec or
                        otherwise expect very high viewership, please shoot us
                        an email with more details
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="conty6">
                      <Link to="/home/contact-us/">
                        <button className="btn cont-btn">Contact Us</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <div
        id="myModal201"
        className={
          pricingInfoModel == true
            ? "modal fade show custom_modal"
            : "modal fade hide custom_modal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close custom_close3"
                data-dismiss="modal"
                onClick={() => {
                  setPricingInfoModel(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body pricing-model-body">
              <ul className="pricing-model-ul">
                <li>
                  Although we are building the web app to work across all major
                  devices and browsers, design and testing are still in
                  progress.{" "}
                </li>
                <li>
                  Currently, we recommend that the recording of any video
                  Eternaview is done from a Windows PC or Mac, desktop or
                  laptop, using the Google Chrome browser.{" "}
                </li>
                <li>
                  Recording of any audio-only Eternaview should be done on a
                  Windows PC using the Google Chrome browser.{" "}
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              {/*    <button type="button" onClick={this.closePopUp}>
                              Okay!
                            </button> */}
            </div>
            <PricingFaqModel  toggleFaqModel={toggleFaqModel} isPricingFaq={isPricingFaq}/>        
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPlansPage;
