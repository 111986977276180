import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import edit from "../../Images/edit.png";
import { Form, Toast } from "reactstrap";
import connection from "../../config/connection";
import Loader from "react-loader";
import { toast } from "react-toastify";

export default function SecureLogin(props) {
  let [pass, setPass] = useState("");

  let [inputtype, setInputType] = useState(false);
  let [loaded, setLoaded] = useState(true);

  const user = {
    password: "David321",
  };

  useEffect(() => {
    setInputType(false);
    setLoaded(true);
  }, []);

  function showPassword() {
    setInputType(!inputtype);
  }

  const handleFormSubmit = (event) => {
    //Prevent page reload

    event.preventDefault();
    setLoaded(false);

    // if (UnameOrEmail === user.email || UnameOrEmail === user.username) {
    if (pass === user.password) {
      toast("User Loged In");
      localStorage.setItem("pass", "match");
      props.history.push("/home/legacy-dating");
    } else {
      setLoaded(true);
      toast("wrong password");
    }
    // }
    // else {
    //   console.log("please check you username or Email");
    // }
  };

  return (
    <section className="login_outer login67">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 offset-lg-3 offset-md-0">
            <Form
              onSubmit={(event) => handleFormSubmit(event)}
              autoComplete="off"
            >
              <div className="login_inner4">
                <h4>
                  Log in to
                  <Link  onClick={()=>{window.location.href = connection.homePage}}>
                    <span className="etnat"> Eterna</span>
                    <span className="view_outre">view</span>
                  </Link>
                </h4>

                <div className="input_outer99">
                  {inputtype == false && (
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  )}
                  {inputtype == true && (
                    <input
                      type="text"
                      name="password"
                      placeholder="Password"
                      required
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  )}

                  {inputtype == false && (
                    <img src={edit} onClick={showPassword} />
                  )}
                  {inputtype == true && (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={showPassword}
                    ></i>
                  )}
                </div>

                <div className="log_outer">
                  {/* <button type="submit">Log in</button> */}
                  {loaded == true && <button type="submit">Log in</button>}
                  {loaded == false && (
                    <button type="button">Logging in...</button>
                  )}

                  <Loader loaded={loaded}></Loader>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}
