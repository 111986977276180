import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Circles } from "react-loader-spinner";
const MoveTOPracticePage = () =>{
    const history = useHistory()
    const auth = useSelector(state=> state)
    const checkIsUserLoggedIn = () =>{
        const token = sessionStorage.getItem("token");
        if(auth.auth.loginUserToken || token){
            history.push('/create/practice-test/')
        }else{
            history.push('/oauth/sign-in/?navto=practice')
        }
    }
    useEffect(()=>{
        checkIsUserLoggedIn()
    },[])
    return(
        <section className="cretae_home5 new_create_home5">
        <Circles wrapperClass="circle-spinner"/>
     </section>
    )
 }

export default MoveTOPracticePage