import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const Direction = (props) => {
  return (
      <div style={{display:props.isOpen?"block":"none"}} className="modal" id="myModal-demo">
      <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content">

            <div className="modal-header">
               <button
                type="button"  onClick={()=>{props.closeDirection()}}  className="close custom_close3">&times;</button>
            </div>

            <div className="modal-body">
               <section className="doiect_outer">
                  <div className="container">

                     <div className="modal-header">
                        <h4>Directions: </h4>
                     </div>
                     <div className="modal-body">
                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Practice First:</strong> Try out the "Practice First" feature from the Create page to ensure your equipment is working properly, and you're satisfied with the video and audio quality.</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Recommended Devices for Video Eternaviews:</strong>  We recommend that any video Eternaview is recorded on a Windows PC or Mac, desktop or laptop, using the Google Chrome or Safari browsers. If you would like to use a mobile device - i.e. phone or tablet - we would only do recordings in a landscape (horizontal) orientation at this time.</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Question Modules:</strong> Your Eternaview will consist of approximately 190 questions divided into modules. Feel free to skip irrelevant questions or entire modules.</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Retakes:</strong> You can retake any of your recordings right after you make them and again at the end of the process. For any questions that you skip, you will be given another chance to answer them at the end.</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Take Breaks:</strong> You can create your Eternaview over multiple sittings. After hitting "Submit" on your latest recorded answer, take a break, and all your progress will be saved.</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Be Creative:</strong> Move your recording device around to showcase your answers. Whether it's cooking, gardening, or playing the piano, have fun with it!</p>

                        <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Answer Length:</strong> We recommend you keep your answers to roughly two minutes or less. This is for technical reasons and to maintain viewers’ attention. In no case should you record an answer longer than four minutes.</p>

                        {/* <p className="margin_direction_modal"><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Audio-only Eternaviews:</strong> Recording of any audio-only Eternaview (as opposed to video) should be done on a Windows PC using the Google Chrome browser.</p> */}
                        <button type="button"  
                        onClick={()=>{props.closeDirection()}}
                          className="continue-button" 
                          data-dismiss="modal"
                          >Continue</button>

                     </div>

                  </div>
               </section>
            </div>

            <div className="modal-footer">
               {/*                                         <button type="button" data-toggle="modal" data-target="#myModal2" data-dismiss="modal">Continue</button>
*/}                                    </div>
         </div>
      </div>
   </div>
  );
};

export default Direction;
